import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'

interface LoginDialogProps {
    isOpen: boolean
    onDialogClose: () => void
    onDialogSubmit: (email: string, password: string) => void
    errorMessage: boolean
    onResetError: () => void
}

const LoginDialog: React.FC<LoginDialogProps> = (props: LoginDialogProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onDialogClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault()
                    const formData = new FormData(event.currentTarget)
                    const formJson = Object.fromEntries((formData as any).entries())
                    props.onDialogSubmit(formJson.email, formJson.password)
                },
            }}
        >
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter your credentials to login</DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={props.onResetError}
                />
                <TextField
                    required
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={props.onResetError}
                />
                {props.errorMessage && (
                    <Typography variant="subtitle1" sx={{ color: 'red' }}>
                        Please input correct credentials.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDialogClose}>Cancel</Button>
                <Button type="submit">Login</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LoginDialog

import LocalParkingOutlined from '@mui/icons-material/LocalParkingOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import RoomService from '@mui/icons-material/RoomService'
import ShowerOutlined from '@mui/icons-material/ShowerOutlined'
import WifiPasswordOutlined from '@mui/icons-material/WifiPasswordOutlined'
import {
    AppBar,
    Box,
    Button,
    Container,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-scroll'
import DoubleBedAc from '../../../assets/double-bed/double-ac.jpg'
import DoubleBedNonAc from '../../../assets/double-bed/double-non-ac.jpg'
import FourBedAc from '../../../assets/four-bed/four-bed-ac.webp'
import FourBedNonAc from '../../../assets/four-bed/four-bed-non-ac.webp'
import LogoImage from '../../../assets/sag-logo-orange.png'
import TopImageMobile from '../../../assets/top-banner-mobile.jpg'
import TopImage from '../../../assets/top-banner.jpg'
import GenericAlertDialog from '../../../components/genericAlertDialog/GenericAlertDialog'
import { addCustomerEnquiry } from '../../../services/appServices/customerEnquiryService'
import { numberRegex } from '../../../utilities/regexUtilities'

function HomePage() {
    const initialState = {
        customerName: '',
        customerEmail: '',
        customerMobile: '',
        numberOfMembers: '',
        message: '',
    }
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState('Home')
    const [formData, setFormData] = useState(initialState)
    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    }

    const handleCloseAndScroll = (to: string) => {
        setDrawerOpen(false)
        // This is where we tell react-scroll to smoothly scroll to the section
        setTimeout(() => {
            // @ts-ignore
            document.getElementById(to).scrollIntoView({ behavior: 'smooth' })
        }, 200) // Small delay to wait until the drawer is closed
    }

    const menuItems = [
        { text: 'Home', link: 'home' },
        { text: 'About', link: 'about' },
        { text: 'Packages', link: 'packages' },
        { text: 'Enquiry', link: 'enquiry' },
    ]

    const amenities = [
        {
            label: 'Free WiFi',
            icon: WifiPasswordOutlined,
        },
        {
            label: 'On Demand Service',
            icon: RoomService,
        },
        {
            label: 'Hot Bathing Water',
            icon: ShowerOutlined,
        },
        {
            label: 'Free Parking (First Come First Serve)',
            icon: LocalParkingOutlined,
        },
    ]

    const packages = [
        {
            image: DoubleBedNonAc,
            pricing: '1000',
            type: 'Non-AC Double Bed',
        },
        {
            image: DoubleBedAc,
            pricing: '1300',
            type: 'AC Double Bed',
        },
        {
            image: FourBedNonAc,
            pricing: '2000',
            type: 'Non-AC Four Bed',
        },
        {
            image: FourBedAc,
            pricing: '2400',
            type: 'AC Four Bed',
        },
    ]

    const onQuerySubmit = async (e: any) => {
        e.preventDefault()
        const result = await addCustomerEnquiry(formData)
        if (result.status) {
            setFormData(initialState)
            setIsSuccessOpen(true)
            setAlertMessage(result.message)
        } else {
            setIsSuccessOpen(true)
            setAlertMessage(result.message)
        }
    }

    const handleOnlyNumber = (e: any) => {
        if (numberRegex.test(e.target.value)) {
            handleChange(e)
        }
    }

    // Handle form field changes
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const onSuccessOkClick = () => {
        setIsSuccessOpen(false)
        window.location.reload()
    }

    return (
        <>
            <div>
                {/* Responsive Navigation */}
                <AppBar position="fixed">
                    <Toolbar sx={{ bgcolor: '#C4E1F6' }}>
                        <Typography sx={{ flexGrow: 1 }}>
                            <img alt="logo-image" src={LogoImage} width={80} />
                        </Typography>
                        <Typography component={'span'} color="#FF9D3D" sx={{ pr: '8px', fontWeight: 'bold' }}>
                            Contact #:
                        </Typography>
                        <Typography
                            component={'a'}
                            href="tel:+919450404150"
                            color="#FF9D3D"
                            sx={{ pr: '16px', textDecoration: 'none', fontWeight: 'bold' }}
                        >
                            +91-9450404150
                        </Typography>
                        {/* Desktop Menu */}
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {menuItems.map((item) => (
                                <Button
                                    key={item.text}
                                    sx={{
                                        color: '#FF9D3D',
                                        textDecoration: selectedMenu === item.text ? 'underline' : 'none',
                                        textDecorationThickness: '1px',
                                        textUnderlineOffset: '4px',
                                    }}
                                >
                                    <Link
                                        to={item.link}
                                        onClick={() => setSelectedMenu(item.text)}
                                        smooth={true}
                                        duration={500}
                                        offset={-70}
                                    >
                                        {item.text}
                                    </Link>
                                </Button>
                            ))}
                        </Box>

                        {/* Mobile Menu */}
                        <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' } }}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/* Drawer for Mobile */}
                <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' } }}>
                    <List sx={{ bgcolor: '#C4E1F6', flex: 1, pl: 1, pr: 4 }}>
                        {menuItems.map((item) => (
                            <Link
                                key={item.text}
                                to={item.link}
                                smooth={true}
                                duration={500}
                                offset={-70}
                                onClick={() => handleCloseAndScroll(item.link)}
                            >
                                <ListItemText
                                    onClick={() => setSelectedMenu(item.text)}
                                    sx={{
                                        color: '#FF9D3D',
                                        textDecoration: selectedMenu === item.text ? 'underline' : 'none',
                                        textDecorationThickness: '2px',
                                        textUnderlineOffset: '4px',
                                        py: '2px',
                                    }}
                                    primary={item.text}
                                />
                            </Link>
                        ))}
                    </List>
                </Drawer>

                {/* Home Section */}
                <Container id="home" maxWidth={false} sx={{ padding: '50px 0', width: '100%' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: { xs: 'flex-end', md: 'center' },
                        }}
                    >
                        {/* Image */}
                        <Box
                            component="img"
                            src={isMobile ? TopImageMobile : TopImage}
                            alt="Hotel"
                            sx={{ width: '100%', height: 'auto' }}
                        />
                        {/* Text */}
                        <Box
                            sx={{
                                position: 'absolute',
                                color: 'white', // Adjust color as per the image background
                                textAlign: 'center',
                                fontWeight: 'bold',
                                mb: isMobile ? '16px' : 0,
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xs: '24px',
                                        md: '48px',
                                    },
                                }}
                            >
                                Welcome to the Kashi, City of Lord Shiva
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: { xs: '16px', md: '24px' }, mb: { xs: '20px', md: '40px' } }}>
                                "बाबा विश्वनाथ की नगरी काशी में आपका स्वागत है|"
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: { xs: '16px', md: '24px' }, px: { xs: '20px', md: '28px' } }}>
                                {isMobile
                                    ? "Kashi (Varanasi): One of the world's oldest and most vibrant cities, alive with culture and spirituality."
                                    : "Kashi, also known as Varanasi, is a city that has stood the test of time, being one of the oldest and still thriving cities in the world. Its rich cultural heritage and spiritual significance continue to draw visitor's from around the globe."}
                            </Typography>
                        </Box>
                    </Box>
                </Container>

                {/* About Section */}
                <Container id="about" sx={{ padding: '50px 0', backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        About Us
                    </Typography>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Paper sx={{ padding: 1 }}>
                                <Box component={'img'} src={TopImage} width={'100%'} height={'auto'}></Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper sx={{ padding: 2, height: 'auto' }}>
                                <Typography variant="body1" align="center" sx={{ pb: '16px', textAlign: 'justify' }}>
                                    Situated in the heart of Kashi (Varanasi),{' '}
                                    <span style={{ fontWeight: 'bold' }}>Hotel Shiv Atithi Grih</span> has been a beacon of warm hospitality
                                    and comfort for tourists since its establishment in 1995. For over 25 years, the hotel has upheld a
                                    strong reputation for providing a welcoming and delightful experience to its guests.
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ textAlign: 'justify' }}>
                                    Conveniently located, the hotel offers easy access to the city's renowned cultural and spiritual
                                    landmarks. Embracing the rich heritage of Kashi, Hotel Shiv Atithi Grih provides a tranquil oasis amidst
                                    the vibrant city. The establishment's commitment to exceptional service and attention to detail has
                                    earned it a loyal following among visitors.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

                {/* Packages Section */}
                <Container id="packages" sx={{ padding: '50px 0' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Our Packages
                    </Typography>
                    <Grid container spacing={3}>
                        {packages.map((pack) => (
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                    <Paper sx={{ padding: 2 }}>
                                        <Box
                                            component="img"
                                            src={pack.image}
                                            alt="room-image"
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                            }}
                                        />

                                        {/* Text Overlay */}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                width: { xs: '86.5%', md: '91.5%' },
                                                bgcolor: 'rgba(128, 128, 128, 0.8)',
                                                color: 'white',
                                                px: 1,
                                                mb: '20px',
                                                height: 'auto',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontSize: { xs: '14px' } }}
                                                >{`${pack.type} Starting @`}</Typography>
                                                <Typography variant="body1" sx={{ pr: '10px', fontSize: { xs: '14px' } }}>
                                                    {'Amenities'}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontSize: { xs: '14px' } }}
                                                >{`₹${pack.pricing} /Night`}</Typography>
                                                <Box>
                                                    {amenities.map((icon) => (
                                                        <Tooltip title={icon.label} placement="top">
                                                            <IconButton sx={{ color: '#fff', padding: '4px', fontSize: { xs: '14px' } }}>
                                                                {<icon.icon />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>

                {/* Enquiry Section */}
                <Container id="enquiry" sx={{ padding: '50px 0', backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Enquiry Form
                    </Typography>
                    <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12} sm={6} sx={{ display: isMobile ? 'none' : 'block' }}>
                            <Paper
                                sx={{
                                    backgroundColor: '#C4E1F6',
                                    display: 'flex',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box component={'span'} sx={{ p: 2 }}>
                                    <Typography component={'span'} sx={{ display: 'flex', pb: 8 }}>
                                        <Typography variant="h4" sx={{ color: '#FFF', fontWeight: 'bold' }}>
                                            {'Contact Us at # '}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            component={'a'}
                                            href="tel:+919450404150"
                                            color="#FF9D3D"
                                            sx={{ pl: '16px', textDecoration: 'none', fontWeight: 'bold' }}
                                        >
                                            +91-9450404150
                                        </Typography>
                                    </Typography>
                                    <Box component={'span'}>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontSize: '24px', color: '#FFF', fontWeight: 'bold', textAlign: 'justify' }}
                                        >
                                            For any group individual or group related query you call us or also you can drop a query to us.
                                            We will reach you back.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper sx={{ padding: 3 }}>
                                <Box component={'form'} onSubmit={onQuerySubmit}>
                                    <TextField
                                        label="Name"
                                        name="customerName"
                                        value={formData.customerName}
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                        required
                                    />
                                    <TextField
                                        label="Mobile"
                                        name="customerMobile"
                                        value={formData.customerMobile}
                                        fullWidth
                                        margin="normal"
                                        type="tel"
                                        onChange={handleOnlyNumber}
                                        required
                                        slotProps={{ htmlInput: { minLength: 10, maxLength: 10 } }}
                                    />
                                    <TextField
                                        label="Email"
                                        name="customerEmail"
                                        value={formData.customerEmail}
                                        fullWidth
                                        margin="normal"
                                        type="email"
                                        required
                                        onChange={handleChange}
                                    />

                                    <FormControl fullWidth required={true} margin="normal">
                                        <InputLabel id="number-of-members-label">Number of Members</InputLabel>
                                        <Select
                                            labelId="number-of-members-label"
                                            id="select-customer-id-type"
                                            name="numberOfMembers"
                                            value={formData.numberOfMembers}
                                            label="Number of Members"
                                            onChange={handleChange}
                                            required
                                        >
                                            {/* @ts-ignore*/}
                                            {[...Array(50).keys()]
                                                .map((i) => i + 1)
                                                .map((numbers) => (
                                                    <MenuItem value={numbers}>{numbers}</MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Message"
                                        name="message"
                                        value={formData.message}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="normal"
                                        onChange={handleChange}
                                        required
                                    />
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        style={{ backgroundColor: 'rgba(50, 129, 126, 0.87)', width: '100%' }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

                {/* Footer */}
                <Box sx={{ backgroundColor: '#333', color: '#fff', padding: 2, textAlign: 'center' }}>
                    <Typography variant="body2">&copy; {new Date().getFullYear()} Shiv Atithi Grih. All rights reserved.</Typography>
                </Box>
            </div>
            <GenericAlertDialog isOpen={isSuccessOpen} message={alertMessage} okBtnText="OK" okBtnClick={onSuccessOkClick} />
        </>
    )
}

export default HomePage

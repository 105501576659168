import { AppBar, Button, Stack, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoginDialog from '../../components/loginDialog/LoginDialog'
import { getEncryptedData, getLocalData, setEncryptedData, setLocalData } from '../../encryption/crypto'
import { login, logout } from '../../services/firebase/firebaseAuth'
import { EncryptionSingleton } from '../../services/singleton/EncryptionSingleton'

const pages = [
    { label: 'List Entry', path: '/admin/entryList' },
    { label: 'List Expenses', path: '/admin/expenseList' },
    { label: 'Add Entry', path: '/admin/addEntry' },
    { label: 'Add Expense', path: '/admin/addExpense' },
    { label: 'Invoicing', path: '/admin/invoicing' },
]

const NavBar: React.FC = () => {
    const navigate = useNavigate()
    const encKey = EncryptionSingleton.getInstance().getEncKey()
    /*****State Variables*****/
    const [activeMenu, setActiveMenu] = useState('List Entry')
    const [isUserLogin, setIsUserLogin] = useState(false)
    const [loginModal, setLoginModal] = useState<boolean>(false)
    const [authErrorMsg, setAuthErrorMsg] = useState<boolean>(false)
    /*****State Variables*****/

    useEffect(() => {
        const isUid = getLocalData('id')
        if (isUid) setIsUserLogin(true)
        const savedNav = getEncryptedData('menuActive', encKey)
        if (savedNav) {
            setActiveMenu(savedNav)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoginSubmit = async (email: string, password: string) => {
        try {
            const authData = await login(email, password)
            console.log('>>>authData<<<', JSON.stringify(authData))
            setLoginModal(false)
            setLocalData('id', authData.user.uid)
            setEncryptedData('userInfo', authData, authData.user.uid)
            navigate('/admin/entryList')
            // window.location.reload()
        } catch (err: any) {
            console.error('>>>Error<<<', err)
            setAuthErrorMsg(true)
        }
    }

    const onLogin = () => {
        setLoginModal(true)
    }

    const onLogout = async () => {
        setIsUserLogin(false)
        localStorage.clear()
        await logout()
        navigate('/admin')
        window.location.reload()
    }

    const setActiveMenuItem = (menuName: string) => {
        setActiveMenu(menuName)
        setEncryptedData('menuActive', menuName, encKey)
    }

    return (
        <>
            <AppBar position="static" sx={{ bgcolor: 'rgba(50, 129, 126, 0.87)' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    {isUserLogin ? (
                        <Stack direction={'row'} spacing={2}>
                            {pages.map((page, index) => (
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to={page.path}
                                    key={index}
                                    onClick={() => setActiveMenuItem(page.label)}
                                    sx={{
                                        bgcolor: activeMenu === page.label ? 'rgba(50, 129, 100, 0.87)' : 'transparent',
                                    }}
                                >
                                    {page.label}
                                </Button>
                            ))}
                        </Stack>
                    ) : (
                        <Button
                            color="inherit"
                            component={Link}
                            to={'/admin'}
                            sx={{
                                bgcolor: 'rgba(50, 129, 100, 0.87)',
                            }}
                        >
                            Home
                        </Button>
                    )}
                    <Button variant="outlined" color="inherit" onClick={isUserLogin ? onLogout : onLogin}>
                        {isUserLogin ? 'Logout' : 'Login'}
                    </Button>
                </Toolbar>
            </AppBar>
            <LoginDialog
                isOpen={loginModal}
                onDialogClose={() => setLoginModal(false)}
                onDialogSubmit={onLoginSubmit}
                errorMessage={authErrorMsg}
                onResetError={() => setAuthErrorMsg(false)}
            />
        </>
    )
}

export default NavBar

import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useState } from 'react'
import CheckboxComponent from '../../../../components/checkboxComponent/CheckboxComponent'
import GenericAlertDialog from '../../../../components/genericAlertDialog/GenericAlertDialog'
import { addManualBookings } from '../../../../services/appServices/addManualBookingService'
import { styles } from './style'

const AddEntry: React.FC = () => {
    const styleValues = styles()
    const initialState = {
        billNo: '',
        arrivalDateTime: null,
        checkoutDateTime: null,
        rooms: [],
        gawlName: '',
        paymentType: '',
        paymentAmount: '',
        gawliAmount: '',
        paymentDate: null,
        customerInfo: [{ customerName: '', mobileNo: '', idType: '', idNumber: '' }],
    }
    const [formData, setFormData] = useState(initialState)
    const [roomSelectionError, setRoomSelectionError] = useState<boolean>(false)
    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    const roomList = [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '25',
        '25',
        'Hall 1',
        'Hall 2',
        'Hall 3',
    ]

    // Handle form field changes
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const arrivalDateTimeChange = (newValue: any) => {
        setFormData({
            ...formData,
            arrivalDateTime: newValue,
        })
    }

    const checkoutDateTimeChange = (newValue: any) => {
        setFormData({
            ...formData,
            checkoutDateTime: newValue,
        })
    }

    const paymentDateChange = (newValue: any) => {
        setFormData({
            ...formData,
            paymentDate: newValue,
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        console.log('Form submitted with:', formData)
        const result = await addManualBookings(formData)
        if (result.status) {
            setFormData(initialState)
            setIsSuccessOpen(true)
            setAlertMessage(result.message)
        } else {
            setIsSuccessOpen(true)
            setAlertMessage(result.message)
        }
    }

    // Delete a row by index
    const handleDeleteRow = (index: number) => {
        const updatedRows = formData.customerInfo.filter((_, i) => i !== index)
        setFormData({ ...formData, customerInfo: updatedRows })
    }

    // Handle change for each input in a specific row
    const handleCustomerChange = (index: number, event: any) => {
        const { name, value } = event.target
        const updatedRows = [...formData.customerInfo]
        // @ts-ignore
        updatedRows[index][name] = value
        setFormData({ ...formData, customerInfo: updatedRows })
    }

    // Add a new row
    const handleAddCustomer = () => {
        setFormData({ ...formData, customerInfo: [...formData.customerInfo, { customerName: '', mobileNo: '', idType: '', idNumber: '' }] })
    }

    const handleRoomChange = (roomsList: string[]) => {
        if (roomsList.length === 0) {
            setRoomSelectionError(true)
        } else {
            setRoomSelectionError(false)
            // @ts-ignore
            setFormData({ ...formData, rooms: roomsList })
        }
    }

    const onSuccessOkClick = () => {
        setIsSuccessOpen(false)
        window.location.reload()
    }
    return (
        <>
            <Typography variant="h5" sx={{ paddingLeft: '1.5em', paddingTop: '1em' }}>
                {'Booking Info'}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box component="form" onSubmit={handleSubmit} className={styleValues.formParentBox}>
                    <Box sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }} className={styleValues.displayFlex}>
                        <TextField
                            label="Bill No"
                            name="billNo"
                            value={formData.billNo}
                            onChange={handleChange}
                            type="number"
                            fullWidth
                            required
                            className={styleValues.numberInput}
                        />
                        <DateTimePicker
                            label="Arrival Date and Time"
                            name="arrivalDateTime"
                            value={formData.arrivalDateTime}
                            onChange={arrivalDateTimeChange}
                            slotProps={{ textField: { variant: 'outlined', required: true } }}
                            sx={{ width: '100%' }}
                            format={'dd/MM/yyyy hh:mm a'}
                        />
                        <DateTimePicker
                            label="Checkout Date and Time"
                            name="checkoutDateTime"
                            value={formData.checkoutDateTime}
                            onChange={checkoutDateTimeChange}
                            slotProps={{ textField: { variant: 'outlined', required: true } }}
                            sx={{ width: '100%' }}
                            format={'dd/MM/yyyy hh:mm a'}
                        />
                        <TextField label="Gawl Name" name="gawlName" value={formData.gawlName} onChange={handleChange} fullWidth />
                    </Box>
                    <Typography variant="h5" sx={{ paddingTop: '1em' }}>
                        {'Select Room(s)'}
                    </Typography>
                    <Box sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
                        <CheckboxComponent
                            checkboxOptions={roomList}
                            error={roomSelectionError}
                            onCheckBoxChange={(rooms: string[]) => handleRoomChange(rooms)}
                        />
                    </Box>
                    <Box className={styleValues.customerInfoBox}>
                        <Typography variant="h5">{'Customer Info'}</Typography>
                        <IconButton
                            aria-label="add-customer-button"
                            onClick={handleAddCustomer}
                            sx={{
                                padding: 2,
                                backgroundColor: 'rgba(50, 129, 126, 0.57)',
                                ':hover': { backgroundColor: 'rgba(50, 129, 100, 0.87)' },
                            }}
                        >
                            <AddCircleOutlineRounded />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                        }}
                    >
                        {formData.customerInfo.map((custRow, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    paddingBottom: '1em',
                                }}
                            >
                                {/* Customer Name Field */}
                                <TextField
                                    label="Customer Name"
                                    name="customerName"
                                    value={custRow.customerName}
                                    onChange={(e) => handleCustomerChange(index, e)}
                                    fullWidth
                                    required
                                />

                                {/* Mobile No Field */}
                                <TextField
                                    label="Mobile No"
                                    name="mobileNo"
                                    value={custRow.mobileNo}
                                    onChange={(e) => handleCustomerChange(index, e)}
                                    fullWidth
                                    type="tel"
                                    required
                                    slotProps={{ htmlInput: { minLength: 10, maxLength: 10 } }}
                                />
                                {/* Customer Id Type Field */}
                                <FormControl fullWidth required={index === 0 ? true : false}>
                                    <InputLabel id="select-customer-id-type-label">Customer Id Type</InputLabel>
                                    <Select
                                        labelId="select-customer-id-type-label"
                                        id="select-customer-id-type"
                                        name="idType"
                                        value={custRow.idType}
                                        label="Customer Id Type"
                                        onChange={(e) => handleCustomerChange(index, e)}
                                    >
                                        <MenuItem value="AadharCard">Aadhar</MenuItem>
                                        <MenuItem value="VoterId">Voter Id</MenuItem>
                                        <MenuItem value="DrivingLicense">Driving License</MenuItem>
                                        <MenuItem value="Passport">Passport</MenuItem>
                                        <MenuItem value="CitizenshipCertificate">Citizenship Certificate</MenuItem>
                                    </Select>
                                </FormControl>

                                {/* Customer Id No Field */}
                                <TextField
                                    label="Customer Id No."
                                    name="idNumber"
                                    value={custRow.idNumber}
                                    onChange={(e) => handleCustomerChange(index, e)}
                                    fullWidth
                                    required={index === 0 ? true : false}
                                />

                                {/* Delete Row Button */}
                                {formData.customerInfo.length > 1 && (
                                    <IconButton aria-label="delete" onClick={() => handleDeleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Typography variant="h5" sx={{ paddingTop: '1em' }}>
                        {'Payment Info'}
                    </Typography>
                    <Box
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                                paddingBottom: '1em',
                            }}
                        >
                            {/* Payment type Field */}
                            <FormControl fullWidth required>
                                <InputLabel id="select-country-label">Payment Type</InputLabel>
                                <Select
                                    labelId="select-payment-type-label"
                                    id="select-country"
                                    name="paymentType"
                                    value={formData.paymentType}
                                    label="Payment Type"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="UPI Sourabh">UPI Sourabh</MenuItem>
                                    <MenuItem value="UPI Sushma">UPI Sushma</MenuItem>
                                    <MenuItem value="Current Account">Current Account</MenuItem>
                                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                </Select>
                            </FormControl>
                            {/* Payment Date Field */}
                            <DatePicker
                                label="Payment Date"
                                name="paymentDate"
                                value={formData.paymentDate}
                                onChange={paymentDateChange}
                                slotProps={{ textField: { variant: 'outlined', required: true } }}
                                sx={{ width: '100%' }}
                                format={'dd/MM/yyyy'}
                            />
                            {/* Payment Amount Field */}
                            <TextField
                                label="Payment Amount"
                                name="paymentAmount"
                                value={formData.paymentAmount}
                                onChange={handleChange}
                                type="number"
                                fullWidth
                                required
                                className={styleValues.numberInput}
                            />
                            {/* Payment Amount Field */}
                            <TextField
                                label="Gawli Amount"
                                name="gawliAmount"
                                value={formData.gawliAmount}
                                onChange={handleChange}
                                type="number"
                                fullWidth
                                className={styleValues.numberInput}
                            />
                        </Box>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: 'rgba(50, 129, 126, 0.87)', width: '25%', alignSelf: 'end' }}
                    >
                        Add Booking
                    </Button>
                </Box>
            </LocalizationProvider>
            <GenericAlertDialog isOpen={isSuccessOpen} message={alertMessage} okBtnText="OK" okBtnClick={onSuccessOkClick} />
        </>
    )
}

export default AddEntry

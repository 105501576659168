import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/configuration'

export const getManualBookings = async () => {
    try {
        // Reference the collection

        const querySnapshot = await getDocs(collection(db, 'manualBookings'))

        // Map each document to its data and return as an array
        const dataArray = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        return dataArray
    } catch (error) {
        console.error('Error retrieving collection:', error)
        return []
    }
}

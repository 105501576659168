import { makeStyles } from '@mui/styles'

export const styles = makeStyles({
    numberInput: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield', // Firefox
            '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
            margin: 0, // Ensures consistency across browsers
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none', // Chrome, Safari, Edge
            margin: 0,
        },
    },
    formParentBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: '2em',
    },
    displayFlex: {
        display: 'flex',
    },
    customerInfoBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
})

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface GenericAlertDialogProps {
    isOpen: boolean
    message: string
    okBtnText: string
    cancelBtnText?: string
    okBtnClick: () => void
    cancelBtnClick?: () => void
}

const GenericAlertDialog = (props: GenericAlertDialogProps) => {
    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.cancelBtnClick}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{props.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.cancelBtnText && props.cancelBtnClick && <Button onClick={props.cancelBtnClick}>{props.cancelBtnText}</Button>}
                    <Button onClick={props.okBtnClick}>{props.okBtnText}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default GenericAlertDialog

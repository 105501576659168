import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase/configuration'

interface BookingInfo {
    billNo: string
    arrivalDateTime: any
    checkoutDateTime: any
    rooms: string[]
    gawlName: string
    paymentType: string
    paymentAmount: string
    gawliAmount: string
    paymentDate: any
    customerInfo: CustomerInfo[]
}

export interface CustomerInfo {
    customerName: string
    mobileNo: string
    idType: string
    idNumber: string
}

export const addManualBookings = async (bookingInfo: BookingInfo) => {
    try {
        const bookingDocRef = await addDoc(collection(db, 'manualBookings'), {
            primaryId: `${new Date(bookingInfo.arrivalDateTime).getFullYear()}-${bookingInfo.billNo}`,
            billNo: bookingInfo.billNo,
            arrivalDateTime: bookingInfo.arrivalDateTime,
            checkoutDateTime: bookingInfo.checkoutDateTime,
            rooms: bookingInfo.rooms,
            gawlName: bookingInfo.gawlName,
            paymentType: bookingInfo.paymentType,
            paymentAmount: bookingInfo.paymentAmount,
            gawliAmount: bookingInfo.gawliAmount,
            paymentDate: bookingInfo.paymentDate,
            customerInfo: bookingInfo.customerInfo,
            timestamp: serverTimestamp(),
        })
        console.log('Document written with ID: ', bookingDocRef.id)
        return {
            status: true,
            message: `Booking Successfully added with ID: ${bookingDocRef.id}`,
            data: bookingDocRef.id,
        }
    } catch (error: any) {
        console.error('Error adding document: ', error)
        return {
            status: false,
            message: `Error adding document: ${error.message}`,
            data: error,
        }
    }
}

import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined'
import WhatsApp from '@mui/icons-material/WhatsApp'
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Grid2'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import { useState } from 'react'
import LogoImage from '../../../../assets/sag-logo-black.png'
import CheckboxComponent from '../../../../components/checkboxComponent/CheckboxComponent'
import { numberRegex, numberUptoTwoDecimalRegex } from '../../../../utilities/regexUtilities'
import { invoicingStyle } from './InvoicingStyle'

const InvoiceForm = () => {
    const roomList = [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        'Hall 1',
        'Hall 2',
        'Hall 3',
    ]
    const initialInvoice = {
        billNo: '',
        customerName: '',
        customerMobile: '',
        customerGstin: '',
        customerAddress: '',
        billingDate: null,
        arrivalDateTime: null,
        checkoutDateTime: null,
        roomNumbers: [],
        male: '',
        female: '',
        child: '',
        financialYear: '',
        roomDetails: [{ days: '', rentPerDay: '' }],
        cgst: '6',
        sgst: '6',
    }
    const styleValues = invoicingStyle()
    const [invoice, setInvoice] = useState(initialInvoice)
    const [receiptData, setReceiptData] = useState<any>(null)
    const [roomSelectionError, setRoomSelectionError] = useState<boolean>(false)

    const handlePrint = () => {
        //@ts-ignore
        const printContent = document.getElementById('receipt-preview').innerHTML
        const originalContent = document.body.innerHTML
        document.body.innerHTML = printContent
        window.print()
        document.body.innerHTML = originalContent
        window.location.reload() // reload page to recover form data
    }

    const calculateSubtotal = () => {
        const val = receiptData.roomDetails.reduce((total: any, item: any) => total + item.days * item.rentPerDay, 0)
        return val - val * 0.12
    }

    const calculateTax = () => {
        const amtTotal = receiptData.roomDetails.reduce((total: any, item: any) => total + item.days * item.rentPerDay, 0)
        return (amtTotal * receiptData.cgst) / 100
    }

    const calculateTotal = () => {
        return calculateSubtotal() + calculateTax() * 2
    }

    // Handle form field changes
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setInvoice({
            ...invoice,
            [name]: value.toUpperCase(),
        })
    }

    const arrivalDateTimeChange = (newValue: any) => {
        setInvoice({
            ...invoice,
            arrivalDateTime: newValue,
        })
    }

    const checkoutDateTimeChange = (newValue: any) => {
        setInvoice({
            ...invoice,
            checkoutDateTime: newValue,
        })
    }

    const invoiceDateChange = (newValue: any) => {
        setInvoice({
            ...invoice,
            billingDate: newValue,
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        console.log('Form submitted with:', JSON.stringify(invoice))
        setReceiptData(invoice)
    }

    const handleRoomChange = (roomsList: string[]) => {
        if (roomsList.length === 0) {
            setRoomSelectionError(true)
        } else {
            setRoomSelectionError(false)
            // @ts-ignore
            setInvoice({ ...invoice, roomNumbers: roomsList })
        }
    }

    // Add a new row
    const handleBookedRoomInformation = () => {
        setInvoice({ ...invoice, roomDetails: [...invoice.roomDetails, { days: '', rentPerDay: '' }] })
    }

    // Handle change for each input in a specific row
    const handleRoomUpdates = (index: number, event: any) => {
        const { name, value } = event.target
        const updatedRows = [...invoice.roomDetails]
        // @ts-ignore
        updatedRows[index][name] = value
        setInvoice({ ...invoice, roomDetails: updatedRows })
    }

    // Delete a row by index
    const handleDeleteRow = (index: number) => {
        const updatedRows = invoice.roomDetails.filter((_, i) => i !== index)
        setInvoice({ ...invoice, roomDetails: updatedRows })
    }

    const handleOnlyNumber = (e: any) => {
        if (numberRegex.test(e.target.value)) {
            handleChange(e)
        }
    }

    const sortedRooms = (arr: string[]) => {
        let numbers = arr.filter((x) => !isNaN(Number(x))).sort()
        let alphabets = arr.filter((x) => isNaN(Number(x))).sort()
        return [...numbers, ...alphabets]
    }

    const calculateTwelveReducing = (amount: string) => {
        return Number(amount) - Number(amount) * 0.12
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Typography variant="h5" sx={{ paddingLeft: '1.5em', paddingTop: '1em' }}>
                    {'Invoicing Information'}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} className={styleValues.formParentBox}>
                    <Box sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }} className={styleValues.displayFlex}>
                        <TextField
                            label="Bill No"
                            name="billNo"
                            value={invoice.billNo}
                            onChange={handleChange}
                            type="number"
                            fullWidth
                            required
                            className={styleValues.numberInput}
                        />
                        <TextField
                            label="Financial Year"
                            name="financialYear"
                            value={invoice.financialYear}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <DateTimePicker
                            label="Arrival Date and Time"
                            name="arrivalDateTime"
                            value={invoice.arrivalDateTime}
                            onChange={arrivalDateTimeChange}
                            slotProps={{ textField: { variant: 'outlined', required: true } }}
                            sx={{ width: '100%' }}
                            format="dd/MM/yyyy hh:mm a"
                        />
                        <DateTimePicker
                            label="Checkout Date and Time"
                            name="checkoutDateTime"
                            value={invoice.checkoutDateTime}
                            onChange={checkoutDateTimeChange}
                            slotProps={{ textField: { variant: 'outlined', required: true } }}
                            sx={{ width: '100%' }}
                            format="dd/MM/yyyy hh:mm a"
                        />
                        <DatePicker
                            label="Date"
                            name="billingDate"
                            value={invoice.billingDate}
                            onChange={invoiceDateChange}
                            slotProps={{ textField: { variant: 'outlined', required: true } }}
                            sx={{ width: '100%' }}
                            format="dd/MM/yyyy"
                        />
                    </Box>
                    <Box sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2, paddingTop: 2 }} className={styleValues.displayFlex}>
                        <TextField
                            label="Customer Name"
                            name="customerName"
                            value={invoice.customerName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Customer Mobile"
                            name="customerMobile"
                            value={invoice.customerMobile}
                            onChange={handleOnlyNumber}
                            type="tel"
                            fullWidth
                            required
                            slotProps={{ htmlInput: { minLength: 10, maxLength: 10 } }}
                        />
                        <TextField
                            label="Male"
                            name="male"
                            value={invoice.male}
                            onChange={handleOnlyNumber}
                            fullWidth
                            required
                            type="tel"
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 3 } }}
                            sx={{ maxWidth: 100 }}
                        />
                        <TextField
                            label="Female"
                            name="female"
                            value={invoice.female}
                            onChange={handleOnlyNumber}
                            fullWidth
                            required
                            type="tel"
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 3 } }}
                            sx={{ maxWidth: 100 }}
                        />
                        <TextField
                            label="Children"
                            name="child"
                            value={invoice.child}
                            onChange={handleOnlyNumber}
                            fullWidth
                            required
                            type="tel"
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 3 } }}
                            sx={{ maxWidth: 100 }}
                        />
                        <TextField
                            label="Customer GSTIN"
                            name="customerGstin"
                            value={invoice.customerGstin}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>

                    <Box sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2, paddingTop: 2 }} className={styleValues.displayFlex}>
                        <TextField
                            label="Customer Address"
                            name="customerAddress"
                            value={invoice.customerAddress}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Box>
                    <Typography variant="h5" sx={{ paddingTop: '1em' }}>
                        {'Select Room(s)'}
                    </Typography>
                    <Box sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
                        <CheckboxComponent
                            checkboxOptions={roomList}
                            error={roomSelectionError}
                            onCheckBoxChange={(rooms: string[]) => handleRoomChange(rooms)}
                        />
                    </Box>
                    <Box className={styleValues.customerInfoBox}>
                        <Typography variant="h5">{"Booked Room's Information"}</Typography>
                        {invoice.roomDetails.length < 4 && (
                            <IconButton
                                aria-label="add-booking-info-button"
                                onClick={handleBookedRoomInformation}
                                sx={{
                                    padding: 2,
                                    backgroundColor: 'rgba(50, 129, 126, 0.57)',
                                    ':hover': { backgroundColor: 'rgba(50, 129, 100, 0.87)' },
                                }}
                            >
                                <AddCircleOutlineRounded />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                        }}
                    >
                        {invoice.roomDetails.map((roomInfo, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    paddingBottom: '1em',
                                }}
                            >
                                <TextField
                                    label="Number of Days"
                                    name="days"
                                    value={roomInfo.days}
                                    fullWidth
                                    required
                                    type="tel"
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 3 } }}
                                    onChange={(e) => {
                                        if (numberRegex.test(e.target.value)) {
                                            handleRoomUpdates(index, e)
                                        }
                                    }}
                                />

                                <TextField
                                    label="Rent Per Day"
                                    name="rentPerDay"
                                    value={roomInfo.rentPerDay}
                                    type="tel"
                                    fullWidth
                                    required
                                    onChange={(e) => {
                                        if (numberUptoTwoDecimalRegex.test(e.target.value)) {
                                            handleRoomUpdates(index, e)
                                        }
                                    }}
                                />

                                {invoice.roomDetails.length > 1 && (
                                    <IconButton aria-label="delete" onClick={() => handleDeleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: 'rgba(50, 129, 126, 0.87)', width: '25%', alignSelf: 'end' }}
                    >
                        Generate Receipt
                    </Button>
                </Box>
            </LocalizationProvider>

            {/* preview section */}
            {receiptData && (
                <Box sx={{ paddingLeft: 10 }}>
                    <Box
                        component={'div'}
                        style={{
                            height: 560,
                            width: 794,
                            border: '1px solid black',
                        }}
                        id="receipt-preview"
                    >
                        <Box component={'div'} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                            <Box component={'div'} style={{ textAlign: 'center' }}>
                                <h4
                                    style={{
                                        display: 'inline-block',
                                        backgroundColor: 'black',
                                        marginTop: 0,
                                        marginBottom: 2,
                                        color: 'white',
                                        margin: 1,
                                    }}
                                >
                                    Tax Invoice
                                </h4>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Grid2 container spacing={2} alignItems={'center'}>
                                    <Grid2 size={2}>
                                        <img
                                            src={LogoImage}
                                            alt="sag-logo"
                                            width={100}
                                            style={{ border: '1px solid black', borderRadius: 5 }}
                                        />
                                    </Grid2>
                                    <Grid2 size={8}>
                                        <Grid2 container direction="column" alignItems={'center'} justifyContent={'center'}>
                                            <Typography variant={'h4'} style={{ marginBottom: 0 }}>
                                                Shiv Atithi Grih
                                            </Typography>
                                            <Typography sx={{ marginTop: 0, fontSize: '10px' }}>
                                                D 54/158 S, Ramkund (Sant Nagar, Gurubagh Road) Varanasi (U.P.) - 221010
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <Grid2 container direction="column" alignItems={'flex-end'} spacing={1}>
                                            <Grid2 container spacing={0.5}>
                                                <Grid2>
                                                    <IconButton
                                                        aria-label="whatsApp"
                                                        onClick={() => null}
                                                        sx={{ margin: 0, height: 8, width: 8 }}
                                                    >
                                                        <WhatsApp />
                                                    </IconButton>
                                                </Grid2>
                                                <Grid2>
                                                    <IconButton
                                                        aria-label="phone"
                                                        onClick={() => null}
                                                        sx={{ margin: 0, height: 8, width: 8 }}
                                                    >
                                                        <PhoneIphoneOutlined />
                                                    </IconButton>
                                                </Grid2>
                                                <Grid2>
                                                    <Typography variant={'caption'} style={{ marginBottom: 0 }}>
                                                        9450404150
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Grid2 container spacing={0.5}>
                                                <Grid2>
                                                    <IconButton
                                                        aria-label="phone-iphone-outlined"
                                                        onClick={() => null}
                                                        sx={{ margin: 0, height: 8, width: 8 }}
                                                    >
                                                        <PhoneIphoneOutlined />
                                                    </IconButton>
                                                </Grid2>
                                                <Grid2>
                                                    <Typography variant={'caption'} style={{ marginBottom: 0 }}>
                                                        9335824710
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="caption">HSN/SAC : 00441070</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography variant="caption" sx={{ paddingRight: 1 }}>
                                        GSTIN
                                    </Typography>
                                    <GstDisplay gstin={'09AUVPK3810F1ZF'} />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    paddingTop: '10px',
                                }}
                            >
                                <Typography sx={{ fontSize: '12px' }}>Rooms : {sortedRooms(receiptData.roomNumbers).toString()}</Typography>
                                <Typography sx={{ fontSize: '12px' }}>
                                    No. of Persons : {receiptData.male} (M) {receiptData.female} (F) {receiptData.child} (C)
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography sx={{ fontSize: '12px' }}>
                                    CheckIn : {format(receiptData.arrivalDateTime, 'dd/MM/yyyy hh:mm a')}
                                </Typography>
                                <Typography sx={{ fontSize: '12px' }}>
                                    CheckOut : {format(receiptData.checkoutDateTime, 'dd/MM/yyyy hh:mm a')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between', // Adjust this to control the spacing between the tables
                                    gap: '20px', // Optional: To add some space between the tables
                                    paddingTop: '10px',
                                }}
                            >
                                <TableContainer component={Paper} elevation={0} sx={{ minWidth: 500 }}>
                                    <Table size="small" sx={{ minWidth: 500 }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ minWidth: 60, lineHeight: 1, fontSize: '12px' }}>{'Name : '}</TableCell>
                                                <TableCell align="left" sx={{ minWidth: 250, lineHeight: 1, fontSize: '12px' }}>
                                                    {receiptData.customerName}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ minWidth: 60, lineHeight: 1, fontSize: '12px' }}>{'Address : '}</TableCell>
                                                <TableCell align="left" sx={{ minWidth: 250, lineHeight: 1, fontSize: '12px' }}>
                                                    {receiptData.customerAddress}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ minWidth: 60, lineHeight: 1, fontSize: '12px' }}>{'GSTIN : '}</TableCell>
                                                <TableCell align="left" sx={{ minWidth: 250, lineHeight: 1, fontSize: '12px' }}>
                                                    {receiptData.customerGstin}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer component={Paper} elevation={0} sx={{ maxWidth: 290 }}>
                                    <Table size="small" sx={{ maxWidth: 290 }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>{'F. Year : '}</TableCell>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>{receiptData.financialYear}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>{'Bill No : '}</TableCell>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>{receiptData.billNo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>{'Date : '}</TableCell>
                                                <TableCell sx={{ lineHeight: 1, fontSize: '12px' }}>
                                                    {format(receiptData.billingDate, 'dd/MM/yyyy')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <TableContainer component={Paper} elevation={0} sx={{ marginTop: '10px' }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                                <strong>Number of Days</strong>
                                            </TableCell>
                                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                                <strong>Rent Per Day</strong>
                                            </TableCell>
                                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                                <strong>Total Amount /Room (₹)</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {receiptData.roomDetails.map((row: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    align="center"
                                                    sx={{ lineHeight: 1, fontSize: '12px' }}
                                                >{`${row.days} Day(s)`}</TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ lineHeight: 1, fontSize: '12px' }}
                                                >{`${calculateTwelveReducing(row.rentPerDay)} /Day`}</TableCell>
                                                <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                                    {row.days * calculateTwelveReducing(row.rentPerDay)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableRow>
                                        <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}></TableCell>
                                        <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}></TableCell>
                                        <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                            Sub-Total <strong>₹{calculateSubtotal()}</strong>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '0.5px solid lightgrey',
                                    marginTop: 1,
                                    borderRadius: '3px',
                                    py: '2px',
                                    px: 2,
                                }}
                            >
                                <Typography variant="body2">
                                    <span>CGST @ {receiptData.cgst}% : </span>
                                    <span style={{ fontWeight: 'bold' }}>₹{calculateTax()}</span>
                                </Typography>
                                <Typography variant="body2">
                                    <span>SGST @ {receiptData.sgst}% : </span>
                                    <span style={{ fontWeight: 'bold' }}>₹{calculateTax()}</span>
                                </Typography>
                                <Typography variant="body2">
                                    <span>Total Amount </span>
                                    <span style={{ fontWeight: 'bold' }}>₹{calculateTotal()}</span>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    pt: 1,
                                }}
                            >
                                <Box component={'div'}>
                                    <Typography variant="h6">Thanks for your kind visit.</Typography>
                                    <Typography variant="body2">All Dispute subject to Varanasi Jurisdiction.</Typography>
                                </Box>
                                <Box component={'div'} textAlign={'right'}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mb: 2 }}>
                                        <span>{'For : '}</span>
                                        <Typography variant="h6">Shiv Atithi Grih</Typography>
                                    </Box>
                                    <Typography variant="caption">Signature of Authorized/Representative</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        onClick={handlePrint}
                        style={{
                            backgroundColor: 'rgba(50, 129, 126, 0.87)',
                            width: '25%',
                            alignSelf: 'end',
                            paddingLeft: 30,
                            marginTop: 10,
                        }}
                    >
                        Print Receipt
                    </Button>
                </Box>
            )}
        </>
    )
}
const GstDisplay = ({ gstin }: { gstin: string }) => {
    return (
        <Box sx={{ display: 'flex', gap: '2px' }}>
            {gstin.split('').map((char: string, index: number) => (
                <Box
                    key={index}
                    sx={{
                        border: '1px solid #000', // Black border around each character
                        width: '15px', // Width of each box
                        height: '20px', // Height of each box
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>
                        {char}
                    </Typography>
                </Box>
            ))}
        </Box>
    )
}

const styles = makeStyles({
    customerInfoGrid: {
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingTop: '4px',
        paddingBottom: '4px',
        border: '1px solid #000',
    },
})

export default InvoiceForm

import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../../../navigation/navbar/Navbar'

const AdminLayout: React.FC = () => {
    return (
        <Box>
            <Navbar />
            <Box>
                <Outlet />
            </Box>
        </Box>
    )
}

export default AdminLayout

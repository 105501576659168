import { Box, Container } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

const MainLayout: React.FC = () => {
    return (
        <Box>
            <Container maxWidth={false}>
                {/* Outlet renders the current route’s component */}
                <Outlet />
            </Container>
        </Box>
    )
}

export default MainLayout

// auth.js
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from './configuration'

// Sign up function
export const signup = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
}

// Log in function
export const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
}

// Log out function
export const logout = () => {
    return signOut(auth)
}

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { getManualBookings } from '../../../../services/appServices/getManualBookingService'

const EntryList: React.FC = () => {
    useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        const bookings = await getManualBookings()
        console.log('>>>bookings<<<', JSON.stringify(bookings))
    }
    return (
        <Box sx={{ px: 2 }}>
            <TableContainer component={Paper} elevation={0} sx={{ marginTop: '10px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                <strong>Number of Days</strong>
                            </TableCell>
                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                <strong>Rent Per Day</strong>
                            </TableCell>
                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                <strong>Total Amount /Room (₹)</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* <TableRow key={index}>
                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>{`${row.days} Day(s)`}</TableCell>
                            <TableCell
                                align="center"
                                sx={{ lineHeight: 1, fontSize: '12px' }}
                            >{`${calculateTwelveReducing(row.rentPerDay)} /Day`}</TableCell>
                            <TableCell align="center" sx={{ lineHeight: 1, fontSize: '12px' }}>
                                {row.days * calculateTwelveReducing(row.rentPerDay)}
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default EntryList

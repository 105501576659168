import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { getLocalData } from '../encryption/crypto'
import AddEntry from '../modules/admin/postLogin/addEntry/AddEntry'
import AddExpense from '../modules/admin/postLogin/addExpense/AddExpense'
import EntryList from '../modules/admin/postLogin/entryList/EntryList'
import ExpenseList from '../modules/admin/postLogin/expenseList/ExpenseList'
import Invoicing from '../modules/admin/postLogin/invoicing/Invoicing'
import AdminHome from '../modules/admin/preLogin/adminHome/AdminHome'
import NotFound from '../modules/admin/preLogin/notFound/notFound'
import AdminLayout from '../modules/layouts/adminLayout/AdminLayout'
import MainLayout from '../modules/layouts/mainLayout/MainLayout'
import HomePage from '../modules/main/homePage/HomePage'

const Navigation: React.FC = () => {
    const isLoggedIn = getLocalData('id')
    return (
        <Router>
            {/* <Navbar /> */}
            <Routes>
                {/* Main routes */}
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    {/* <Route path="about" element={<AboutPage />} /> */}
                </Route>

                {/* Admin routes */}
                <Route path="/admin" element={<AdminLayout />}>
                    {!isLoggedIn && <Route path="/adminHome" element={<AdminHome />} />}
                    <Route path="/admin/entryList" element={<EntryList />} />
                    <Route path="/admin/addExpense" element={<AddExpense />} />
                    <Route path="/admin/expenseList" element={<ExpenseList />} />
                    <Route path="/admin/addEntry" element={<AddEntry />} />
                    <Route path="/admin/invoicing" element={<Invoicing />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default Navigation

import { EncryptionSingleton } from '../services/singleton/EncryptionSingleton'

const CryptoJS = require('crypto-js')

// Function to generate random key
export const generateSecretKey = (length = 32) => {
    const array = new Uint8Array(length)
    window.crypto.getRandomValues(array)
    const key = Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('')
    EncryptionSingleton.getInstance().setEncKey(key)
    return key
}

// Encryption function
const encryptData = (data: any, secretKey: string) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
}

// Decryption function
const decryptData = (cipherText: string, secretKey: string) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey)
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedData)
}

// Store encrypted data in localStorage
export const setEncryptedData = (key: string, data: any, secretKey: string) => {
    const encryptedData = encryptData(data, secretKey)
    localStorage.setItem(key, encryptedData)
}

// Retrieve and decrypt data from localStorage
export const getEncryptedData = (key: string, secretKey: string) => {
    const encryptedData = localStorage.getItem(key)
    if (encryptedData) {
        return decryptData(encryptedData, secretKey)
    }
    return null
}

// Store local data in localStorage
export const setLocalData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data))
}

// Retrieve local data from localStorage
export const getLocalData = (key: string) => {
    const data = localStorage.getItem(key)
    if (data) {
        return JSON.parse(data)
    }
    return null
}

import { Container, Typography } from '@mui/material'

const Home: React.FC = () => {
    return (
        <Container>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
                This is home page for admin
            </Typography>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                This is property of Shiv Atithi Grih
            </Typography>
        </Container>
    )
}

export default Home

export class EncryptionSingleton {
  private static instance: EncryptionSingleton;
  encKey: string;

  private constructor() {
    this.encKey = '';
  }

  public static getInstance(): EncryptionSingleton {
    if (!EncryptionSingleton.instance) {
      EncryptionSingleton.instance = new EncryptionSingleton();
    }
    return EncryptionSingleton.instance;
  }

  setEncKey = (value: string) => {
    this.encKey = value;
  };

  getEncKey = () => {
    return this.encKey;
  };
}

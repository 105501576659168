import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase/configuration'

interface CustomerInformation {
    customerName: string
    customerMobile: string
    customerEmail: string
    numberOfMembers: string
    message: string
}

export const addCustomerEnquiry = async (custInfo: CustomerInformation) => {
    try {
        const addCustomerEnquiryRef = await addDoc(collection(db, 'customerEnquiry'), {
            customerName: custInfo.customerName,
            customerMobile: custInfo.customerMobile,
            customerEmail: custInfo.customerEmail,
            numberOfMembers: custInfo.numberOfMembers,
            message: custInfo.message,
            timestamp: serverTimestamp(),
        })
        console.log('Document written with ID: ', addCustomerEnquiryRef.id)
        return {
            status: true,
            message: `Request Successfully submitted with ID: ${addCustomerEnquiryRef.id}`,
            data: addCustomerEnquiryRef.id,
        }
    } catch (error: any) {
        console.error('Error adding document: ', error)
        return {
            status: false,
            message: `Error adding request: ${error.message}`,
            data: error,
        }
    }
}

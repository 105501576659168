import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material'
import { useState } from 'react'

interface CheckboxComponentProps {
    error: boolean
    checkboxOptions: string[]
    onCheckBoxChange: (selected: string[]) => void
}

const CheckboxComponent = (props: CheckboxComponentProps) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])

    const handleCheckboxChange = (event: { target: { value: any; checked: any } }) => {
        const { value, checked } = event.target
        if (checked) {
            setSelectedOptions([...selectedOptions, value])
            props.onCheckBoxChange([...selectedOptions, value])
        } else {
            setSelectedOptions(selectedOptions.filter((option) => option !== value))
            props.onCheckBoxChange(selectedOptions.filter((option) => option !== value))
        }
    }

    return (
        <FormControl error={props.error}>
            <FormGroup row>
                {props.checkboxOptions.length > 0 ? (
                    props.checkboxOptions.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            control={<Checkbox value={option} checked={selectedOptions.includes(option)} onChange={handleCheckboxChange} />}
                            label={option}
                        />
                    ))
                ) : (
                    <FormHelperText>Loading options...</FormHelperText>
                )}
            </FormGroup>
            {props.error && <FormHelperText>You must select at least one option</FormHelperText>}
        </FormControl>
    )
}

export default CheckboxComponent

// firebaseConfig.js
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyB36eA6-QnrKLomu2Iyge9lC_OBCfbuMn0',
    authDomain: 'shivatithigrih-1996.firebaseapp.com',
    projectId: 'shivatithigrih-1996',
    storageBucket: 'shivatithigrih-1996.appspot.com',
    messagingSenderId: '140274070582',
    appId: '1:140274070582:web:b8c2e3a58d0513aafad497',
    measurementId: 'G-QG1KPKE5HS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore and Authentication
export const db = getFirestore(app)
export const auth = getAuth(app)
